import React, { useState } from "react"
import { MdAccountCircle } from "@react-icons/all-files/md/MdAccountCircle"

import "./card.scss"
import Modal from "../Modal"
import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"

const FounderCard = ({ data }) => {
  const [modal, setModal] = useState(false)

  function modalToggle() {
    setModal(!modal)
  }

  const handleClose = e => {
    // Controls event delegation from bubbling
    e.stopPropagation()
    // Closes modal
    setModal(false)
  }
  return (
    <>
      <div class="card">
        <div class="card-content">
          <div class="content">
            {data.twitter ? (
              <a
                href={`https://twitter.com/` + data.twitter}
                className="founder-twitter"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={data.img || `/images/female-placeholder.jpg`}
                  className="-image"
                  alt={data.name}
                  loading="lazy"
                />
                <div className="svg-wrapper">
                  <FaTwitter fontSize={24} />
                </div>
              </a>
            ) : (
              <img
                src={data.img || `/images/female-placeholder.jpg`}
                className="-image"
                alt={data.name}
                loading="lazy"
              />
            )}
            <h5 className="-title">{data.name}</h5>
            <p className="-desc">{data.desc}</p>
            <button className="button is-rounded" onClick={modalToggle}>
              <span className="icon">
                <MdAccountCircle />
              </span>
              <span>Full Bio</span>
            </button>
          </div>
          {/* <footer>
        </footer> */}
        </div>
      </div>
      <Modal modal={modal} data={data} close={handleClose}>
        {data.statement ? <h4>{data.statement}</h4> : ""}
        {data.desc}
      </Modal>
    </>
  )
}

export default FounderCard
