import React from "react"

import { FaTwitter } from "@react-icons/all-files/fa/FaTwitter"

import "./style.scss"

const Modal = ({ children, data, modal, close }) => {
  return (
    <div className={modal ? "modal -show" : "modal"}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <img
            src={data.img || `/images/female-placeholder.jpg`}
            className="-image"
            alt={data.name}
            loading="lazy"
          />
          <h5 className="modal-card-title">{data.name}</h5>
          {data.twitter ? (
            <a
              href={`https://twitter.com/` + data.twitter}
              className="twitter"
              target="_blank"
              rel="noreferrer"
            >
              <FaTwitter fontSize="24" />
            </a>
          ) : (
            ""
          )}
        </header>
        <section className="modal-card-body">{children}</section>
        <footer className="modal-card-foot">
          {/* <button className="button is-success">Save changes</button> */}
          <button className="button" onClick={close}>
            Close
          </button>
        </footer>
      </div>
    </div>
  )
}

export default Modal
