import React from 'react'
import Layout from '../components/layout'
import FounderCard from '../components/ui/Card'
import Seo from '../components/seo'

import '../assets/Scss/templates/founders.scss'

const foundersList = [
  {
    name: "Naimah Hassan",
    twitter: "NymaHassan",
    img: "/images/founders/naimah.png",
    statement: "Meet the founding women making waves in their communities.",
    desc: "Naimah Hassan is a London-based leader and women's rights activist working within the international development space for 13 years. She specializes in media, campaigning, advocacy and communications. She’s also Program Director at the Global Media Campaign to End FGM and a champion for African women’s rights."
  },
  {
    name: "MamLisa Camara",
    twitter: "MonaCamara1",
    img: "/images/founders/mona.jpg",
    statement: "Meet the founding women working for peace.",
    desc: "MamLisa Camara is a leading grassroots campaigner with extensive experience in community development, as well as women and children’s rights programs. Her activism is supported by the Soroptimist International, a worldwide volunteer service organization for women who work for peace, and to improve the lives of women and girls. She is also the National Coordinator for Safe Hands for Girls in Gambia."
  },
  {
    name: "Reem Abdellatif",
    twitter: "Reem_Abdellatif",
    img: "/images/founders/reem.jpg",
    statement: "Meet the founding women leading by example.",
    desc: "Reem Abdellatif is an Egyptian-American writer and founder of Redefined, a boutique communications agency founded in the Netherlands. Reem is part of the first generation of women in her family to escape Female Genital Mutilation (FGM) in Egypt. As a survivor of gender-based violence and child abuse, Reem is now a Life Coach and an advocate of bodily autonomy, helping women and girls #ReclaimTheNarrative in MENA and Africa."
  },
  {
    name: "Domtila Chesang",
    twitter: "Cdomtila",
    img: "/images/founders/domtila.jpeg",
    statement: "Meet the founding women leading by example.",
    desc: "Domtila Chesang is a Kenyan women’s rights activist known for her campaign against female genital mutilation. She is the founder and director of I_Rep Foundation, which is established to address various forms of violence against girls and women. Chesang was born and raised in West Pokot County."
  },
  {
    name: "Ifrah Ahmed",
    twitter: "",
    img: "/images/founders/ifrah.jpg",
    statement: "",
    desc: "Ifrah Ahmed is a Somali-Irish activist, campaigner, and civil society director. She founded the United Youth of Ireland, an NGO that provides support to young immigrants in their business, creative, and artistic aspirations. She is also the founder of the Ifrah Foundation, a charitable organization that is committed to eliminating Female Genital Mutilation (FGM) in Africa. Ifrah is also a High Profile Supporter of the UNHCR, the United Nations Refugee Agency."
  }
]

function Founders() {
  return (
    <Layout bodyClass="founders-page">
      <Seo title="Meet our Founders" />
      <section className="container">
        <header className="section-header">
          <h1>Meet Our Founders!</h1>
        </header>
        <div className="founders">
          {foundersList.map((founder, i) => {
            return <FounderCard key={i} data={founder} />
          })}
        </div>
      </section>
    </Layout>
  )
}

export default Founders
